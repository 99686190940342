.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-Home {
  margin-top: 15px;
  position: relative;
}
@media (max-width: 767px) {
  .crm-Home.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.crm-Home__calendar {
  height: calc(100vh - 64px - 57px);
}
@media (min-width: 767px) {
  .crm-Home__calendar {
    height: calc(100vh - 45px - 30px);
  }
}
.crm-Home__statTab {
  padding: 1em;
}
