@import '../../../semantic/dist/semantic.css';
@import '../../../node_modules/react-perfect-scrollbar/dist/css/styles.css';
@import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-Header {
  background: #fbfbfb;
  display: grid;
  align-items: center;
  grid-template-columns: 50px 1fr 100px;
  grid-template-rows: 64px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.14);
  z-index: 1;
  max-width: 100vw;
}
@media (max-width: 767px) {
  .crm-Header {
    height: 64px;
  }
}
@media (min-width: 767px) {
  .crm-Header {
    grid-template-columns: 80px 1fr 250px;
    grid-template-rows: 1fr;
  }
}
.crm-Header__burger {
  display: grid;
  justify-content: right;
}
@media (min-width: 767px) {
  .crm-Header__burger {
    visibility: hidden;
  }
}
.crm-Header__box {
  position: relative;
  max-height: 45px;
  text-align: right;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 0 0.5em;
}
.crm-Header__search {
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}
.crm-Header__search .results {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}
.crm-Header__search .results > div.result.estate {
  background: rgba(251, 189, 8, 0.08);
}
.crm-Header__search .results > div.result.owner {
  background: rgba(165, 103, 63, 0.1);
}
.crm-Header__search .results > div.result.contact {
  background: rgba(0, 171, 209, 0.06);
}
.crm-Header__search .results > div.result.user {
  background: rgba(33, 186, 69, 0.06);
}
.crm-Header__search .results > div.result.comment {
  background: #f8f9fa;
}
.crm-Header__search .results > div.result.comment .title {
  font-weight: 400;
}
.crm-Header__search .results > div.result.comment .description {
  font-weight: 400;
}
.crm-Header__search i.close.icon {
  cursor: pointer !important;
  pointer-events: auto !important;
}
@media (min-width: 767px) {
  .crm-Header__search {
    grid-template-columns: 1fr 30px;
  }
}
.crm-Header__search > .search > .ui.icon.input {
  width: 100%;
  max-width: 1000px;
}
.crm-Header__search > .search > .results {
  width: 100vw !important;
  max-width: 1000px !important;
  left: -50px !important;
  margin-top: 3px !important;
}
@media (min-width: 767px) {
  .crm-Header__search > .search > .results {
    left: 0;
    width: 100%;
    max-width: 1000px;
  }
}
.crm-Header__search > .search > .results > .category {
  min-height: 40px !important;
}
.crm-Header__search > .search > .results > .category > .name {
  padding: 8px 5px !important;
  font-size: 12px !important;
  word-break: break-all !important;
}
.crm-Header__search > .search > .results > .category > .result {
  padding: 5px 16px !important;
}
.crm-Header__search > .search > .results > .category > .result > .image {
  height: 33px !important;
  width: 33px !important;
}
.crm-Header__notifications_feed {
  max-height: calc(100vh - 250px) !important;
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block;
  width: calc(100vw - 40px);
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@media (min-width: 767px) {
  .crm-Header__notifications_feed {
    max-height: 400px !important;
    width: initial;
    min-width: 450px;
  }
}
.crm-Header__notifications_feed .ui.avatar.image {
  width: 50px !important;
  height: 50px !important;
}
.crm-Header__notifications_feed .item {
  display: grid !important;
  grid-template-columns: 55px 1fr;
}
.crm-Header__notifications_header {
  margin-right: 0 !important;
  left: 0 !important;
  margin-top: -5px !important;
}
.crm-Header__notifications_description {
  margin-top: 0.625em !important;
}
