.crm-Contacts__itemList {
  margin: 15px 0;
}
.crm-Contacts__itemList_card {
  padding-bottom: 15px !important;
}
.crm-Contacts__itemList_cardContent {
  border-top: 0 !important;
  position: relative;
}
