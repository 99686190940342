.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-callEvents__phoneIcon {
  cursor: pointer;
}
.crm-callEvents__phoneIcon:hover {
  opacity: 0.7 !important;
}
.crm-callEvents__descriptionTrigger {
  margin-left: 15px;
  display: inline-block;
}
.crm-callEvents__notyPopup {
  padding-right: 65px !important;
}
.crm-callEvents__feedItem {
  position: relative;
}
.crm-callEvents__feedItem_callBtn {
  position: absolute;
  top: 27px;
  right: -7px;
}
.crm-callEvents__feedItem_titleBtn {
  color: #181f31;
  margin-right: 25px;
}
.crm-callEvents__feedItem_titleBtn:hover {
  border-bottom: 1px dashed #181f31;
}
.crm-callEvents__feedItem_checkbox {
  margin-right: 10px;
}
.crm-callEvents__callHasReleased * {
  text-decoration: line-through;
}
