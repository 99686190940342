.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-HiddenBlock__header {
  text-align: right;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.crm-HiddenBlock__toggle {
  color: #7e7f81;
  white-space: nowrap;
}
.crm-HiddenBlock__toggle_title {
  border-bottom: 1px dashed #7e7f81;
}
.crm-HiddenBlock__toggle:hover {
  color: #8d8e90;
}
.crm-HiddenBlock__toggle:hover .crm-HiddenBlock__toggle_title {
  border-bottom: transparent;
}
.crm-HiddenBlock__toggle_triangle {
  display: inline-block;
  margin-left: 3px;
}
.crm-HiddenBlock__box {
  margin-top: 10px;
  padding: 5px;
}
