.crm-SmartTextArea__loading {
  animation: flashBorder 2s linear infinite;
}
.crm-SmartTextArea__selection::selection {
  color: #9F3A38;
  text-shadow: none;
}
.crm-SmartTextArea__error {
  margin-right: 1em;
  color: #9F3A38;
  cursor: pointer;
  display: inline-block;
}
@keyframes flashBorder {
  0% {
    border-color: white;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: white;
  }
}
