.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-Chat__sidenav_header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 25px 0.5rem 0;
  background-color: #f9fafb;
  height: 128px;
}
.crm-Chat__user_hd {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.crm-Chat__avatar {
  min-width: 50px;
  margin-right: 1rem;
}
.chat-user-item .crm-Chat__avatar {
  padding: 0 5px;
  min-width: 10px;
}
.Chat__user_hd .crm-Chat__avatar {
  cursor: pointer;
}
.crm-Chat__userInfo {
  flex: 1;
  font-size: 0.875rem;
  font-weight: 400;
  box-sizing: border-box;
  display: flex!important;
  flex-direction: column!important;
  justify-content: center!important;
  flex-wrap: nowrap;
  color: #181f31;
}
.crm-Chat__userInfo_title {
  font-weight: 400;
  color: #252525;
  margin: 0;
}
.crm-Chat__userInfo_detail {
  color: #9e9e9e !important;
}
.crm-Chat__tabs {
  background-color: #f9fafb;
}
.crm-Chat__user {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.crm-Chat__user.crm-Chat__user_center {
  justify-content: center;
  align-items: center;
}
.crm-Chat__user.crm-Chat__user_center .crm-Chat__avatar {
  margin-left: auto;
}
.crm-Chat__user_item {
  border-bottom: solid 1px #e9ecef;
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  cursor: pointer;
  max-height: 96px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.crm-Chat__user_item.active,
.crm-Chat__user_item:hover {
  background-color: #b8f2ff;
}
.crm-Chat__user_row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  align-items: center;
}
.crm-Chat__user_avatar {
  padding: 0 5px;
  min-width: 10px;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.crm-Chat__user_avatar_mode {
  position: relative;
  max-width: 50px;
}
.crm-Chat__info {
  padding: 0 5px;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}
.crm-Chat__info_name {
  font-weight: 400;
  margin: 0 0 3px;
  color: #252525;
  font-size: 15px;
}
.crm-Chat__info_des {
  color: #6c757d;
  font-size: 13px;
}
.crm-Chat__info_time {
  font-size: 11px;
  color: #8a92a5;
}
.crm-Chat__unread {
  padding: 0 5px;
  text-align: right;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.crm-Chat__unread_badge {
  background-color: #00abd1;
  color: #fff;
  font-weight: 400;
  padding: 3px 8px;
  line-height: inherit;
  margin-bottom: 6px;
  border-radius: 50%;
  display: inline-block;
  font-size: 75%;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.crm-Chat__message {
  padding: 16px;
  display: flex;
  flex-wrap: nowrap;
}
.crm-Chat__message_card {
  border-radius: 20px 20px 20px 0;
  padding: 8px 12px;
  -o-box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  position: relative;
  margin-left: 16px;
  max-width: 600px;
  background-color: #b8f2ff;
  margin-right: 0;
}
.crm-Chat__message_reverse {
  flex-direction: row-reverse;
}
.crm-Chat__message_reverse .crm-Chat__message_card {
  margin-right: 16px;
  margin-left: 0;
  border-radius: 20px 20px 0 20px;
  background-color: #fff;
}
.crm-Chat__message_reverseMessage {
  text-align: right;
  width: 100%;
  display: inline-block;
}
.crm-Chat__footer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-top: 1px solid #e9ecef;
  padding: 16px;
  background-color: #fff;
  width: 100%;
  align-items: center;
}
.crm-Chat__mainContent {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: calc(100vh - 64px - 1px);
  padding-top: 56px;
}
@media (min-width: 767px) {
  .crm-Chat__mainContent {
    padding-top: 96px;
    height: calc(100vh - 45px - 1px);
  }
}
.crm-Chat__mainContent_messages {
  flex: 2;
  max-height: calc(100% - 105px);
  position: relative;
}
