.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-User {
  padding: 1em;
  position: relative;
}
.crm-User__photo {
  max-height: 250px;
}
.crm-User__photoBox {
  min-height: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.crm-User__link {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 10px;
}
.crm-User__link_a {
  margin-left: 8px;
}
.crm-User__infoBlock_header {
  display: flex;
  align-content: center;
  margin-bottom: 0.333rem;
}
.crm-User__infoBlock_buttons {
  margin: 10px 0 5px;
  text-align: center;
}
.crm-User__infoBlock_label {
  width: 110px;
  display: inline-block;
  font-weight: 500;
  font-size: 0.9em;
}
.crm-User__infoBlock_hiddenImage {
  padding: 5px;
  display: inline-block;
}
.crm-User__contactBlock_phoneInput {
  width: 140px !important;
  margin: 3px 0 3px 22px;
}
.crm-User__contactBlock_firstPhone {
  border: 1px solid #21BA45;
}
.crm-User__contactBlock_firstPhoneLabel {
  display: inline !important;
  font-weight: 400 !important;
  padding-left: 5px;
  white-space: nowrap;
}
.crm-User__contactBlock_emailInput {
  width: 100%;
  margin: 12px 0 0;
}
.crm-User__contactBlock_saveBtn {
  margin-top: 3px !important;
}
.crm-User__photoBlock_editor {
  display: inline-block;
  width: 306px;
  height: 306px;
  position: relative;
  border: 3px dashed #ccc;
  background: white;
}
.crm-User__photoBlock_buttonsSet {
  text-align: center;
}
.crm-User__messages {
  max-height: 400px;
  overflow-y: auto;
}
.crm-User__messages_controlsArea {
  display: grid;
  grid-template-columns: 1fr 50px;
  justify-content: center;
  align-content: center;
}
.crm-User__messages_sendBtn {
  text-align: center;
}
.crm-User__linkList_trigger {
  color: #00abd1;
  cursor: pointer;
}
