.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-Map__marker {
  background: #07f;
  height: 22px;
  max-height: 22px;
  padding: 4px;
  padding-bottom: 10px;
  border-radius: 4px;
  text-align: center;
  color: white !important;
  line-height: 1em;
  font-size: 12px;
  display: block;
  width: 100% !important;
  min-width: 100%;
}
.crm-Map__marker:visited {
  background: #5b5c73;
}
.crm-Map__marker:visited:after {
  border-top-color: #5b5c73;
}
.crm-Map__marker.owners {
  background: #a5673f;
}
.crm-Map__marker.owners:after {
  border-top-color: #a5673f;
}
.crm-Map__marker.external {
  background: #3fa54d;
}
.crm-Map__marker.external:after {
  border-top-color: #3fa54d;
}
.crm-Map__marker.active,
.crm-Map__marker:hover {
  background: #f03825;
  color: #1b2337 !important;
}
.crm-Map__marker.active:after,
.crm-Map__marker:hover:after {
  border-top-color: #f03825;
  color: #1b2337 !important;
}
.crm-Map__marker:after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -27px;
  left: calc(50% - 10px);
  border: 10px solid transparent;
  border-top: 17px solid #07f;
}
.crm-Map__marker_cluster {
  width: 90px;
  min-width: 90px;
  padding: 2px 2px 4px;
  display: grid !important;
  grid-template-columns: 20px 1fr;
  align-items: center;
}
.crm-Map__marker_counter {
  height: 18px;
  width: 18px;
  background: white;
  color: #00abd1;
  display: inline-block;
  border-radius: 9px;
  line-height: 18px;
}
.crm-Map__marker.active .crm-Map__marker_counter {
  background: #1b2337;
  color: white;
}
.crm-Map__markerMini {
  height: 6px;
  max-height: 6px;
  width: 6px;
  line-height: 6px;
  background: #07f;
  border-radius: 3px;
  overflow: hidden;
  display: block;
}
.crm-Map__markerMini.owners {
  background: #a5673f;
}
.crm-Map__markerMini.owners:after {
  border-top-color: #a5673f;
}
.crm-Map__estateMap {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 0;
  bottom: 0;
  left: 0;
  right: 55%;
}
.crm-Map__estateMap_list {
  position: absolute;
  width: 100%;
  top: 0;
  height: calc(100vh - 5rem);
  z-index: 0;
  bottom: 0;
  right: 0;
  left: 45%;
  padding-top: 45px;
  visibility: hidden;
}
@media (min-width: 767px) {
  .crm-Map__estateMap {
    width: 60%;
    right: 40%;
  }
  .crm-Map__estateMap_list {
    width: 40%;
    left: 60%;
    visibility: visible;
  }
}
@media (min-width: 1199px) {
  .crm-Map__estateMap {
    width: 45%;
    right: 55%;
  }
  .crm-Map__estateMap_list {
    width: 55%;
    left: 45%;
  }
}
.crm-Map__estateMap_estates {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 1rem;
  padding: 0 1rem;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.crm-Map__chips {
  z-index: 500;
  position: absolute;
  top: 55px;
  right: 10px;
  margin-left: 60px;
  text-align: right;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.crm-Map__chips > div {
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}
.crm-Map__chipsMobile {
  z-index: 1000;
  cursor: default;
  direction: ltr;
  display: flex;
  width: 100%;
  padding: 8px 0 16px;
  overflow: auto;
  border-bottom: none;
  white-space: nowrap;
  height: 76px;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
}
.crm-Map__chipsMobile_scrollBox {
  margin-bottom: 8px;
  width: 100%;
  overflow: auto;
  border-bottom: none;
}
.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}
