.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-Calendar__event {
  overflow: hidden !important;
  display: inline-block;
  height: 16px;
  line-height: 15px;
  font-size: 15px;
  white-space: nowrap;
}
.crm-Calendar__event_item > .rbc-event {
  border-radius: 2px !important;
}
.crm-Calendar__event_exceed > .rbc-event {
  background-color: #f8d8d5 !important;
  border-color: #f3beb9 !important;
}
.crm-Calendar__event_inlineList {
  display: inline-block;
  margin-left: 15px;
  position: relative;
  bottom: 5px;
}
.crm-Calendar__event_meeting > .rbc-event {
  background-color: rgba(124, 69, 255, 0.8) !important;
  border-color: #412182 !important;
  color: white !important;
}
.crm-Calendar__event_call > .rbc-event {
  background-color: rgba(191, 191, 191, 0.8) !important;
  border-color: #5a5a5a !important;
}
.crm-Calendar__event_showing > .rbc-event {
  background-color: rgba(36, 219, 79, 0.8) !important;
  border-color: #0e4f1e !important;
}
.crm-Calendar__userDropdown {
  margin-left: 12px;
}
.crm-Calendar__userDropdown_box {
  display: inline-block;
}
.rbc-allday-cell {
  top: 17px;
}
.rbc-time-view {
  border: 0;
}
.rbc-today {
  background-color: transparent;
}
.rbc-header {
  height: 48px;
}
.rbc-header > a {
  font-size: 1.2em;
  line-height: 46px;
  display: inline-block;
  height: 46px;
  width: 46px;
}
.rbc-header.rbc-today > a {
  background: #00abd1;
  border-radius: 23px;
  color: #fff;
}
.rbc-label {
  font-family: "Lucida Console", Monaco, monospace;
  font-weight: 200;
  font-size: 0.875em;
}
.rbc-current-time-indicator {
  height: 2px;
  background-color: #f03825;
}
.rbc-current-time-indicator:before {
  width: 16px;
  height: 16px;
  display: block;
  content: '';
  position: absolute;
  border: 8px solid #f03825;
  border-radius: 8px;
  top: -7px;
}
.rbc-time-content {
  overflow-y: auto;
}
.rbc-time-slot {
  width: 50px;
}
.rbc-time-slot > .rbc-time-slot-label {
  position: relative;
  bottom: 10px;
  background: #fff;
  right: 5px;
  padding-right: 3px;
}
.rbc-day-slot .rbc-event {
  min-height: 40px;
  background-color: #B3E1F7;
  border-color: #81CDF2;
  color: #5b5c73;
  border-radius: 3px;
  flex-flow: column-reverse;
}
.rbc-day-slot .rbc-event:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}
.rbc-time-column > .rbc-timeslot-group:first-child .rbc-label {
  visibility: hidden;
}
