.crm-Bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  white-space: nowrap;
  height: 34px;
  will-change: auto;
}
.crm-Bottom__tab {
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  transition: background-color 0.4s;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  height: 34px;
  padding: 8px 4px 7px 8px;
  position: relative;
  margin: 0 6px;
  display: inline-block;
  overflow: hidden;
  animation-name: initBottomTab;
  animation-duration: 0.8s;
}
.crm-Bottom__tab:hover {
  background-color: #f2f3f5;
  transition: background-color 0.1s;
}
.crm-Bottom__tab a {
  color: #1c1e21;
}
.crm-Bottom__tab a:visited {
  color: #1c1e21 !important;
}
.crm-Bottom__remove {
  cursor: pointer;
  font-size: 14px !important;
  position: relative;
  top: 2px;
}
.crm-Bottom__callingTrigger {
  position: absolute;
  right: 30px;
  bottom: 40px;
  cursor: pointer;
}
@keyframes initBottomTab {
  0% {
    top: 0;
  }
  50% {
    top: -30px;
  }
  100% {
    top: 0;
  }
}
@keyframes initCallingTrigger {
  0% {
    right: -100px;
  }
  100% {
    right: 40px;
  }
}
