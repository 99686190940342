.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-List {
  padding: 1em 1em 0;
  position: relative;
}
.crm-List__fullSizeOn {
  margin-left: 245px;
}
.crm-List__fullSizeOnCollapsed {
  margin-left: 71px;
}
.crm-List__fullSizeMobile {
  margin-left: 0;
}
.crm-List__sidebar_modal {
  padding: 0 !important;
  overflow-x: hidden;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  will-change: contents;
}
@media (min-width: 991px) {
  .crm-List__sidebar_modal {
    min-width: 900px !important;
    max-width: 1170px !important;
    width: calc(100% - 70px);
  }
}
.crm-List__sidebar_fullSizePage {
  margin-top: 45px;
  position: relative;
}
.crm-List__sidebar_pusher {
  height: calc(100vh - 64px - 2px);
  overflow-y: visible !important;
  overflow-x: hidden !important;
}
@media print {
  .crm-List__sidebar_pusher {
    height: auto !important;
  }
}
@media (min-width: 767px) {
  .crm-List__sidebar_pusher {
    overflow-y: hidden !important;
    height: calc(100vh - 45px - 5px);
  }
}
.crm-List__sidebar_pusher::-webkit-scrollbar-track {
  background: transparent !important;
}
.crm-List__sidebar_pusher.dimmed:hover:after {
  background-color: rgba(0, 0, 0, 0.6);
  content: "➲";
  font-size: 10rem;
  line-height: 100vh;
  text-indent: 1rem;
  cursor: pointer;
}
.crm-List__sidebar_modalBox {
  padding: calc(50px + 1rem) 0.5rem 1rem;
  overflow-y: visible !important;
}
@media (max-height: 668px) {
  .crm-List__sidebar_modalBox {
    padding-top: 1rem;
  }
}
@media (min-width: 767px) {
  .crm-List__sidebar_modalBox {
    padding: calc(55px + 1rem) 1rem 1rem;
  }
}
.crm-List__sidebar_modalBox_onMap {
  padding-top: 1.25rem;
}
.crm-List__addButton {
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 3;
}
.crm-List__filterWrapper {
  z-index: 1;
}
.crm-List__filter {
  align-items: center;
  padding-top: 0.5em;
  position: relative;
}
.crm-List__filter_moreBtnBottom {
  box-sizing: border-box;
  margin: 0;
  overflow: visible;
  cursor: pointer;
  outline: 0 !important;
  border: 0;
  position: absolute;
  bottom: -20px;
  border-radius: 0 0 6px 6px;
  background: #fff;
  padding: 0 15px 3px;
  font-size: 13px;
  z-index: -1;
  width: 100%;
  opacity: 0.1;
}
.crm-List__filter_moreBtnBottom:hover {
  opacity: 0.5;
  color: #ca1010;
  background: rgba(163, 164, 166, 0.4);
}
.crm-List__filter_archive {
  background: #cc94a3 !important;
}
.crm-List__filter_dropdown {
  padding: 0 0.5em 0.3em !important;
}
.crm-List__filter_dropdown > form > div > div.visible.menu.transition {
  min-width: max-content;
}
.crm-List__filter_dropdownShrink > div.visible.menu.transition {
  max-height: 180px !important;
}
.crm-List__segment {
  padding: 0 !important;
  margin-right: -1px !important;
}
.crm-List__monoField {
  font-family: "Lucida Console", Monaco, monospace;
  font-weight: 200;
  font-size: 0.875em;
  display: inline-block;
  white-space: nowrap;
}
.crm-List__tableComment {
  min-width: 400px !important;
}
.crm-List__tableComment_comment {
  line-height: 1em;
  padding-bottom: 3px;
  white-space: nowrap;
}
.crm-List__tableComment_time {
  font-size: 0.875em;
  color: #5b5c73;
}
.crm-List__tableComment_plus {
  margin-left: 5px;
  color: #00abd1;
}
.crm-List__tableComment_triggerHover {
  visibility: hidden;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.crm-List__tableComment_trigger:hover > .crm-List__tableComment_triggerHover {
  visibility: visible !important;
}
.crm-List__filterType {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px;
  align-content: center;
  z-index: 2;
  position: relative;
}
@media (min-width: 767px) {
  .crm-List__filterType {
    grid-template-columns: 230px 1fr;
    grid-template-rows: 30px;
  }
}
.crm-List__filterType_topBtns {
  z-index: 20;
  display: flex;
  position: relative;
  top: -3px;
  align-items: center;
}
.crm-List__filterType_typeLabel {
  position: absolute;
  top: -15px;
  right: -5px;
}
.crm-List__filterType_typeLabel > div.label {
  cursor: pointer;
}
.crm-List__filterType_headerContent {
  position: relative;
}
.crm-List__calendarMode {
  height: calc(100vh - 155px);
}
.crm-List__pagination_pages {
  white-space: nowrap;
  margin-top: 2em;
}
@media (min-width: 767px) {
  .crm-List__pagination_pages {
    display: inline-block;
    margin-left: 2em;
    margin-top: initial;
  }
}
.crm-List__pagination_pager {
  width: 90px;
}
.crm-List__pagination_header > tr > th {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.crm-List__pagination_nextLoaded > .active.item + a {
  position: relative;
}
.crm-List__pagination_nextLoaded > .active.item + a:after {
  content: '';
  background: rgba(0, 128, 0, 0.4);
  width: 4px;
  height: 4px;
  position: absolute;
  top: 1px;
  right: 2px;
  border-radius: 2px;
  border: 2px;
}
.crm-List__usersGroups {
  height: 100%;
  overflow-y: auto;
  flex: 1 1;
}
.crm-List__usersGroups_short {
  width: 300px;
}
@media (min-width: 767px) {
  .crm-List__usersGroups {
    overflow-y: hidden;
    height: 400px;
    width: 550px;
  }
}
.crm-List__usersGroups_label {
  font-weight: 500;
  margin-left: 15px;
}
.crm-List__usersGroups_userList {
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
}
.crm-List__usersGroups_groups {
  max-height: 370px;
  overflow-x: hidden;
  overflow-y: auto;
}
.ui.menu .ui.dropdown .menu > .item.crm-List__filter_group {
  font-size: 0.8em !important;
  padding: 5px 12px !important;
}
.crm-List__groups_checkbox .ui.checkbox label {
  font-size: 0.8em !important;
}
