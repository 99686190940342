.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-Estate__addForm > div.fields,
.crm-Estate__addForm > div > div.fields {
  margin-bottom: 7px !important;
}
@media (min-width: 767px) {
  .crm-Estate__addForm > div.fields,
  .crm-Estate__addForm > div > div.fields {
    margin-bottom: 15px !important;
  }
}
.crm-Estate__address {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "address" "newbuilding";
}
.crm-Estate__address_nebuildingsOnly {
  grid-template-areas: "newbuilding" !important;
}
@media (min-width: 991px) {
  .crm-Estate__address {
    grid-template-areas: "address" "newbuilding";
  }
}
.crm-Estate__address_newbuilding {
  grid-area: newbuilding;
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 991px) {
  .crm-Estate__address_newbuilding {
    grid-template-columns: 1fr 250px;
  }
}
.crm-Estate__address_newbuildingClear {
  cursor: pointer !important;
  pointer-events: all !important;
}
.crm-Estate__address_address {
  grid-area: address;
  position: relative;
}
.crm-Estate__address_yandexAddressBtn {
  position: absolute;
  top: -31px;
  left: 0;
}
.crm-Estate__address_editing {
  grid-template-columns: 1fr;
  grid-template-areas: "address" "newbuilding";
}
.crm-Estate__address_find > div.ui.input {
  width: 100%;
}
.crm-Estate__address_find > div.results.transition {
  width: 100%;
  margin-top: 0;
  top: calc(100% - 1px);
  border-radius: 0;
  max-height: 400px;
  overflow-y: auto;
}
.crm-Estate__address_find > div.results.transition > .result {
  padding: 4px 12px;
  border-bottom: 0;
}
.crm-Estate__address_find_address {
  width: calc(100% - 47px);
  display: inline-block;
}
.crm-Estate__address_find_mapLink {
  margin-right: 25px;
  display: inline-block;
  margin-bottom: 7px;
}
.crm-Estate__address_notSelected > div.ui.input > input {
  border-color: #ff9898;
  background: #ffe2e2;
}
.crm-Estate__address_map {
  display: inline-block;
}
.crm-Estate__address_yandexSearch > div.results.transition {
  max-height: 350px !important;
  overflow-y: auto;
}
.crm-Estate__field_label {
  width: 150px !important;
  display: inline-block !important;
  font-weight: 400 !important;
}
.crm-Estate__field_labelRooms {
  width: 65px !important;
  display: inline-block !important;
  font-weight: 400 !important;
}
.crm-Estate__field_labelFlex {
  display: inline-block !important;
  font-weight: 400 !important;
  margin-right: 7px !important;
}
.crm-Estate__field_rightMargin {
  margin-right: 20px !important;
  margin-bottom: 10px !important;
}
.crm-Estate__field_tight {
  width: 150px !important;
}
.crm-Estate__squareInput {
  margin-right: 10px;
}
.crm-Estate__squareInput > div.text {
  font-weight: 400 !important;
}
.crm-Estate__squareInput.dropdown.ui > div.menu > div.item {
  padding: 0.45em 1em !important;
  font-size: 1em !important;
}
.crm-Estate__squareInput > label {
  font-size: 0.8em !important;
}
.crm-Estate__squareInput_label {
  font-weight: 300 !important;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}
.crm-Estate__squareInput_room {
  width: 70px !important;
  margin-right: 0;
}
.crm-Estate__squareInput.error > input {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
}
.crm-Estate__fieldNowrap {
  display: inline-block;
  white-space: nowrap;
}
.crm-Estate__marginRight {
  margin-right: 0.25rem;
}
@media (min-width: 1199px) {
  .crm-Estate__roomsCount {
    display: flex;
    align-items: center;
    margin-top: 20px !important;
  }
}
.crm-Estate__description_textarea {
  display: block !important;
  width: 100% !important;
  min-height: 6em !important;
  padding: 3px 6px !important;
}
@media (min-width: 1199px) {
  .crm-Estate__description_textarea {
    width: 100% !important;
  }
}
.crm-Estate__metroList {
  margin-top: 25px;
  color: red;
  position: relative;
}
.crm-Estate__metroList_showMode {
  margin: 3px 0;
}
.crm-Estate__metroList_item {
  margin: 5px auto;
  display: grid;
  padding-right: 80px;
  max-width: 430px;
  grid-template-columns: 40px 20px 1fr 60px;
  grid-gap: 1px;
}
@media (min-width: 767px) {
  .crm-Estate__metroList_item {
    grid-gap: 8px;
  }
}
.crm-Estate__metroList_item_mainRadio {
  align-items: center;
  justify-content: center;
  display: flex;
}
.crm-Estate__metroList_itemShow {
  margin-right: 10px;
  font-size: 0.875em;
  white-space: nowrap;
  display: inline-block;
}
.crm-Estate__filter_archiveLabel {
  color: red;
  margin-left: 20px;
  font-weight: 400;
}
.crm-Estate__filter_field {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2px;
}
@media (min-width: 767px) {
  .crm-Estate__filter_field {
    grid-template-columns: 80px 1fr;
    grid-gap: 8px;
  }
}
.crm-Estate__filter_priceMin {
  width: 95px !important;
}
.crm-Estate__filter_priceMax {
  width: 95px !important;
  margin-right: 27px;
}
.crm-Estate__filter_exportedBases {
  margin-left: 15px;
  display: inline-block;
}
.crm-Estate__filter_exportedBases .visible.menu.transition {
  max-height: 240px;
}
.crm-Estate__about_title {
  color: #626262;
  font-size: 0.875em;
  margin-bottom: 5px;
}
.crm-Estate__about_value {
  font-size: 1.1em;
  font-weight: 400;
  white-space: nowrap;
}
.crm-Estate__photolist {
  min-height: 120px;
  white-space: nowrap;
  overflow: auto;
}
.crm-Estate__photolist_box {
  overflow: hidden;
}
.crm-Estate__photolist_rotate0 {
  cursor: move !important;
}
.crm-Estate__photolist_rotate270 {
  transform: rotate(270deg);
  cursor: move !important;
}
.crm-Estate__photolist_rotate180 {
  transform: rotate(180deg);
  cursor: move !important;
}
.crm-Estate__photolist_rotate90 {
  transform: rotate(90deg);
  cursor: move !important;
}
.crm-Estate__photolist_pult {
  z-index: 2;
  position: relative;
  padding: 2px 0 4px;
  background-color: #f5f6fa;
  opacity: 0.9;
}
.crm-Estate__photolist_pult > * {
  margin-right: 8px !important;
}
.crm-Estate__photolist .image {
  min-width: 100% !important;
}
.crm-Estate__photolist .image > img {
  height: 160px !important;
  min-width: 100%;
  object-fit: cover;
}
.crm-Estate__datePicker > div {
  display: inline-block;
}
.crm-Estate__tableBases {
  display: flex;
  align-items: flex-start;
}
.crm-Estate__mlsIcon {
  display: flex;
  position: relative;
  white-space: nowrap;
  font-size: 0.875em;
  max-width: 7rem;
  min-width: 3rem;
  overflow: hidden;
  padding-right: 10%;
  align-items: baseline;
}
.crm-Estate__mlsIcon:after {
  content: " ";
  width: 15%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), white);
}
.crm-Estate__mlsIcon > * {
  margin-right: 0.25rem;
}
.crm-Estate__metroMap {
  min-width: 800px;
  max-width: calc(100vw - 200px);
  max-height: calc(100vh - 100px);
  margin: 0 auto;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.crm-Estate__metroMap g[id^="label"],
.crm-Estate__metroMap circle[id^="station"] {
  cursor: pointer;
}
.crm-Estate__metroMap_box {
  position: fixed;
  left: 8px;
  top: 8px;
  overflow-y: auto;
  height: 150px;
  width: 170px;
  background: white;
  z-index: 10;
}
.crm-Estate__metroMap_item {
  font-size: 0.75em;
}
.crm-Estate__metroMap_buttons {
  position: fixed;
  right: 15px;
  background: white;
  z-index: 10;
}
.crm-Estate__list_nophoto {
  color: red;
  font-weight: 400;
}
.crm-Estate__list_totalArea {
  max-width: 120px;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}
.crm-Estate__list_time {
  font-size: 0.875em;
}
.crm-Estate__list_favorite {
  display: inline-block;
  position: relative;
  top: 2px;
  right: 2px;
}
.crm-Estate__owner_booked {
  margin: 20px 5px;
}
.crm-Estate__ownersBtns {
  margin: 16px 50px 15px;
}
.crm-Estate__ownersBtn {
  margin-right: 20px !important;
}
.crm-Estate__ownerHistory {
  margin: 10px 10px 20px;
}
.crm-Estate__ownerHistory_btn {
  border-bottom: 1px dotted #8c8c8c;
  margin-left: 15px;
}
.crm-Estate__ownerHistory_list {
  margin-top: 5px;
  background: white;
  padding: 10px;
}
.crm-Estate__ownerHistory a:visited {
  color: #900060;
}
.crm-Estate__roomsSeparator {
  padding: 0.375rem;
  color: black;
  font-weight: bold;
  width: 1rem;
  height: 1rem;
}
.crm-Estate__mapSearchMobile {
  display: grid;
  grid-template-rows: 1fr 56px;
  height: 100%;
}
.crm-Estate__mapSearchMobile_map {
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 0;
}
.crm-Estate__mapSearch {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 0;
}
.crm-Estate__mapSearch_list.ui.sidebar {
  padding-top: 10px !important;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3) !important;
  overflow-x: hidden;
  overflow-y: visible !important;
}
.crm-Estate__mapSearch_list.ui.sidebar.bottom {
  max-height: 60vh;
}
.crm-Estate__mapSearch_counter {
  display: inline-block;
  background: white;
  padding: 4px 6px;
  margin-left: 10px;
  margin-top: 5px;
  border-radius: 0.275rem;
}
@media (min-width: 767px) {
  .crm-Estate__mapSearch_counter {
    margin-top: 45px;
  }
}
.crm-Estate__mapSearch_counterText {
  color: #ca1010;
  display: inline-block;
  position: relative;
  margin-left: 10px;
  height: 0.75rem;
  min-width: 1.5rem;
}
.crm-Estate__link:visited {
  color: #900060 !important;
}
.crm-Estate__link_about {
  color: #cc0c67;
  white-space: nowrap;
}
.crm-Estate__link_address {
  white-space: nowrap;
}
.crm-Estate__export_externalInfo {
  position: relative;
  bottom: 3px;
  display: inline-block;
  z-index: 10;
}
.crm-Estate__export_isExternal {
  text-decoration: line-through;
}
.crm-Estate__export_bet {
  width: 58px;
  margin-left: 9px;
}
.crm-Estate__export_title {
  width: 300px;
}
.crm-Estate__moscowmapBtn {
  position: absolute;
  right: 50px;
  top: -10px;
  display: inline-block;
}
.crm-Estate__mapBox {
  height: 450px;
}
.crm-Estate__fakeLink {
  color: #FBBD08;
}
.crm-Estate__exclusiveLink {
  color: #21BA45;
  font-weight: bold;
}
.crm-Estate__reportTime_label {
  width: 100px;
  position: relative;
  top: 10px;
}
.crm-Estate__reportTime_picker {
  display: inline-block;
}
.crm-Estate__fee {
  display: inline-block;
  white-space: nowrap;
}
.crm-Estate__fee_empty {
  color: red;
}
.crm-Estate__ownerContactTable {
  height: 29.5px;
  line-height: 29.5px;
}
.crm-Estate__availableLine_label {
  width: 150px;
  position: relative;
  top: 10px;
}
.crm-Estate__isFavoriteBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.28571429em;
}
.crm-Estate__cardView {
  margin: 1em 0;
}
.crm-Estate__chips {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  margin: 0 0;
}
.crm-Estate__chips ::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
}
.crm-Estate__chips_mobile {
  cursor: default;
  direction: ltr;
  overflow: auto;
  white-space: nowrap;
  display: flex;
}
.crm-Estate__chips > div {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}
.crm-Estate__redeem {
  white-space: nowrap;
}
.crm-Estate__redeem_info {
  white-space: nowrap;
  font-weight: 200;
  font-size: 0.875em;
  font-style: italic;
  padding-left: 0.5em;
}
.crm-Estate__photoMobile {
  margin-left: -1rem;
  margin-right: -1rem;
  height: 56.25vw;
}
.crm-Estate__photoMobile * .image-gallery-slides {
  height: 56.25vw !important;
}
.crm-Estate__photoMobile * .image-gallery-slides .image-gallery-slide .image-gallery-image {
  height: 56.25vw;
}
.image-gallery-slides {
  height: 160px;
}
.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: cover;
  height: 160px;
}
.image-gallery-icon.image-gallery-right-nav,
.image-gallery-icon.image-gallery-left-nav {
  opacity: 0;
  color: #ccc;
}
.image-gallery-icon.image-gallery-right-nav:hover,
.image-gallery-icon.image-gallery-left-nav:hover {
  opacity: 0.5;
}
@media (min-width: 30em) {
  .filepond--item {
    width: calc(33.33% - 0.5em);
  }
}
@media (min-width: 50em) {
  .filepond--item {
    width: calc(25% - 0.5em);
  }
}
.filepond--root {
  min-height: 8em !important;
}
.filepond--drop-label {
  min-height: 8em !important;
}
