.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-FluentButtons {
  position: relative;
}
.crm-FluentButtons__prevent .crm-FluentButtons__editBtn,
.crm-FluentButtons__prevent .crm-FluentButtons__closeBtn,
.crm-FluentButtons__prevent .crm-FluentButtons__removeBtn,
.crm-FluentButtons__prevent .crm-FluentButtons__saveBtn {
  display: none;
}
.crm-FluentButtons__editBtn,
.crm-FluentButtons__closeBtn,
.crm-FluentButtons__removeBtn,
.crm-FluentButtons__saveBtn {
  position: absolute;
  top: 10px;
  z-index: 2;
}
.crm-FluentButtons__itemDropdown {
  min-height: 2.71428571em;
  display: flex;
}
.crm-FluentButtons__itemDropdown .crm-FluentButtons__editBtn {
  top: 0;
}
.crm-FluentButtons__itemDropdown_link {
  margin: auto 0;
}
.crm-FluentButtons__editBtn,
.crm-FluentButtons__closeBtn {
  right: 10px;
}
.crm-FluentButtons__saveBtn {
  right: 50px;
  color: #21BA45;
}
.crm-FluentButtons__removeBtn:hover {
  color: #f03825 !important;
}
.crm-FluentButtons__editBtn {
  visibility: hidden;
}
.crm-FluentButtons:hover .crm-FluentButtons__editBtn {
  visibility: visible;
}
.crm-PhonesList {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 10px;
}
.form-control {
  padding-left: 48px !important;
  width: 100% !important;
}
