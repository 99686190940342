.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-Statistics__pult_mode {
  position: relative;
  left: 15px;
  top: -4px;
}
.crm-Statistics__modal_table {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}
.crm-Statistics__modal_table thead {
  flex: 0 0 auto;
  width: calc(100% - 9px);
}
.crm-Statistics__modal_table tbody,
.crm-Statistics__modal_table tfoot {
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
  max-height: calc(100vh - 250px);
}
.crm-Statistics__modal_table tbody tr,
.crm-Statistics__modal_table tfoot tr {
  width: 100%;
}
.crm-Statistics__modal_table thead,
.crm-Statistics__modal_table tbody tr,
.crm-Statistics__modal_table tfoot tr {
  display: table;
  table-layout: fixed;
}
.crm-Statistics__modal_firstCol {
  width: 40px;
}
.crm-Statistics__modal_userCol {
  width: 190px;
}
.crm-Statistics__modal_header {
  position: relative;
}
.crm-Statistics__modal_header > .crm-Statistics__modal_groupDropdown {
  right: 65px;
  top: 15px;
}
.crm-Statistics__modal_groupDropdown {
  position: absolute;
  right: 0;
  top: 0;
}
.crm-Statistics__chart {
  width: 100%;
  height: calc(100vh - 180px);
}
.crm-Statistics__chart_legendChecbox {
  margin-right: 15px;
}
.crm-Statistics__chart_tooltip {
  background: white;
  opacity: 0.85;
}
