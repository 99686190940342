.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-Tasks__title {
  display: grid;
  grid-template-columns: 156px 1fr;
  grid-template-rows: 1fr 1fr;
  padding-right: 10px;
  grid-gap: 6px;
}
@media (min-width: 767px) {
  .crm-Tasks__title {
    grid-template-columns: 156px 1fr 210px;
    grid-template-rows: 1fr;
  }
}
.crm-Tasks__title_editing {
  grid-template-columns: 156px 1fr;
  grid-template-rows: 1fr 1fr;
}
.crm-Tasks__assignedUsers {
  display: grid;
  width: 100%;
  grid-template-columns: 156px 1fr;
}
.crm-Tasks__assignedUsers_dropdown {
  position: relative;
  top: -5px;
}
.crm-Tasks__deadLine_label {
  width: 100px;
  position: relative;
  top: 10px;
}
.crm-Tasks__deadLine_picker {
  display: inline-block;
  margin-right: 20px;
}
