.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.crm-Item {
  margin-top: 10px;
}
.crm-Item__disabled {
  text-decoration: line-through;
}
.crm-Item__addMode_editContainer {
  width: 250px;
}
.crm-Item__addMode_buttons {
  margin: 20px 0;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}
.crm-Item__addMode_button {
  margin: 0 15px !important;
}
.crm-Item__editingMode {
  padding-top: 20px;
}
.crm-Item__editingMode > div.fields,
.crm-Item__editingMode > div > div.fields {
  margin-bottom: 7px !important;
}
@media (min-width: 767px) {
  .crm-Item__editingMode > div.fields,
  .crm-Item__editingMode > div > div.fields {
    margin-bottom: 15px !important;
  }
}
.crm-Item__tabMenu {
  z-index: 20;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.crm-Item__tabMenu_mobileSpan {
  min-width: 80px;
}
.crm-Item__tabMenu_closeBtn {
  position: absolute;
  top: 4px;
  left: 2px;
}
@media (max-width: 767px) {
  .crm-Item__tabMenu {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
.crm-Item__tabMenu > .menu > .item {
  height: 47.38px !important;
}
.crm-Item__tabMenu_right {
  position: absolute;
  right: 6px;
  top: 1px;
  background: #f5f6fa;
  z-index: 2;
  display: inline-block;
  height: 39px;
  line-height: 39px;
}
@media (max-width: 767px) {
  .crm-Item__tabMenu_right:before {
    position: absolute;
    right: 0;
    left: -20px;
    width: 20px;
    height: 100%;
    content: '';
    background: #f5f6fa;
    opacity: 0.7;
  }
}
.crm-Item__tabMenu > .ui.menu {
  margin-bottom: 0;
}
.crm-Item__labelWide {
  display: inline-block !important;
  font-weight: 400 !important;
}
.crm-Item__dropping:after {
  content: '';
  display: block;
  background: #ccc !important;
  opacity: 0.5;
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.crm-Item__contactEditingDropdown {
  display: inline-block;
  width: calc(100% - 150px);
}
.crm-Item__comments {
  position: relative;
  margin-top: 1.25em;
}
.crm-Item__listDivider {
  color: #757575 !important;
}
.crm-Item__field_fluid {
  width: 100% !important;
}
.crm-Item__datePickerLabel_column {
  margin-top: 15px !important;
}
@media (min-width: 767px) {
  .crm-Item__datePickerLabel {
    position: relative;
    top: 10px;
  }
}
.crm-Item__itemHistory_trigger {
  color: #00abd1;
  cursor: pointer;
}
.crm-Item__itemHistory_list {
  max-height: 200px;
  overflow-y: auto;
}
.crm-Item__itemHistory_photo {
  margin-right: 10px;
}
.crm-Item__floatingDropdown > .visible.menu.transition {
  min-width: max-content;
}
.crm-Item__preview {
  padding: 0 !important;
}
.crm-Item__preview_loading {
  min-width: 200px;
  margin: 15px;
}
.crm-Item__preview div.item {
  overflow: hidden;
}
.crm-Item__preview_empty {
  padding: 10px;
}
.crm-Item__preview_vertical {
  min-width: 370px !important;
}
.crm-Item__preview_horizontal > div.item > .content,
.crm-Item__preview_vertical > div.item > .content {
  padding: 10px !important;
}
.crm-Item__preview_horizontal > div.item {
  display: grid !important;
  grid-template-columns: 1fr;
  grid-template-rows: 200px 1fr;
}
.crm-Item__preview_horizontal div.image {
  width: 100% !important;
  height: 200px;
}
.crm-Item__preview_horizontal div.image > img {
  object-fit: cover;
  height: 200px !important;
}
.crm-Item__preview_vertical > div.item {
  display: grid !important;
  grid-template-columns: 120px 1fr;
}
.crm-Item__preview_vertical div.image {
  width: 120px !important;
  height: 100% !important;
}
.crm-Item__preview_vertical div.image > img {
  object-fit: cover;
  width: 120px !important;
  height: 100% !important;
}
.crm-Item__preview_photoBox {
  position: relative;
}
.crm-Item__preview_secondPhoto {
  position: absolute;
  bottom: -15px;
  right: 15px;
}
.crm-Item__liveDraft {
  position: absolute;
  right: 10px;
  bottom: 8px;
  display: inline-block;
}
@media (max-width: 767px) {
  .crm-Item__bottomNav {
    margin-bottom: 56px;
    position: relative;
  }
}
.react-datepicker__time-list {
  padding-left: 5px !important;
}
.MuiListItem-button > i.icon {
  position: relative;
  top: -3px;
}
